import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import BackgroundImage from "../images/fundo-home.png";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Página não encontrada" />

    <HeaderImage image={BackgroundImage}>
      <MainLayout size="80vh">
        <Header title="404" subtitle="Página não encontrada" />
      </MainLayout>
    </HeaderImage>
  </Layout>
);

export default NotFoundPage;
